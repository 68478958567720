import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { CategoryModel } from "../models";

@Injectable()
export class CategoryService {
    private apiUrl = environment.serverUrl;
    constructor(private router: Router, private http: HttpClient) {}

    createCategory(category): Observable<any> {
        return this.http.post(`${this.apiUrl}/category`, category)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateCategory(category, catId): Observable<any> {
        return this.http.patch(`${this.apiUrl}/category/${catId}`, category)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getCategories(page?: number, limit?: number): Observable<any>{
        const query = `?page=${page}&limit=${limit}`
        return this.http.get<CategoryModel[]>(`${this.apiUrl}/category${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    deleteCategory(catId: number): Observable<any>{
        return this.http.delete(`${this.apiUrl}/category/${catId}`, {responseType: 'text'})
        .pipe(catchError((error: any) => throwError(error)));
    }
    getCategoryById(categoryId): Observable<any>{
        return this.http.get(`${this.apiUrl}/category/${categoryId}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
}
