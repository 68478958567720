export class ProductModel {
    _id: string;
    description: string;
    name: string;
    base_price: number;
    product_img: string;
    attributes: {};
    categories: [];
    gallery: [];
    shop_name: [];
    related_products: [];
    accessories: {
        cases: [],
        screen_protectors: [],
        power_bricks: []
    };
    sku: number;
    createdAt: string;
    allReadyAssigned: any;
    assignedServices: any;
}