import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//MODAL-DIALOGS 
import { BulkUploadModalComponent } from './shared/modal-dialogs/bulk-upload-modal/bulk-upload-modal.component';
import { AreYouSureComponent } from './shared/modal-dialogs/are-you-sure/are-you-sure.component';

//MODULES
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//COMPONENTS
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';

//SHARED MODULE
import { 
  AuthGuard, 
  AuthService, 
  ClientService, 
  ProductService, 
} from './shared';
import { TokenInterceptor } from './shared/interceptors';

import { AdminComponent } from './admin/admin.component';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeModule } from 'src/assets/theme/theme.module';
import { lightTheme } from 'src/assets/theme/light-theme';
import { darkTheme } from 'src/assets/theme/dark-theme';

// ANGULAR MATERIAL
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MainPipe } from './shared/pipes/main-pipe.module';
import { ComponentsModule } from './shared/components/shared-components.module';

@NgModule({
  imports: [
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDialogModule,
    MatPaginatorModule
  ],
  exports: [
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDialogModule,
    MatPaginatorModule
  ],
  declarations: []
})
export class MaterialModule {}
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminComponent,
    BulkUploadModalComponent,
    AreYouSureComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MaterialModule,
    HttpClientModule,
    NgxFileDropModule,
    FormsModule,
    ReactiveFormsModule,
    MainPipe,
    ComponentsModule,
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light'
    }),
    RouterModule.forRoot(appRoutes),
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ProductService,
    ClientService
  ],
  entryComponents: [BulkUploadModalComponent, AreYouSureComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
