import { throwError, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "..";

@Injectable()
export class AuthService {
  loggedUser;
  _userActionOccured: Subject<void> = new Subject();
  public authUrl: string = environment.authUrl;
  public assetUrl: string = environment.assetUrl;

  
  constructor(private router: Router, private http: HttpClient) {}
  get userActionOccured(): Observable<void>{ return this._userActionOccured.asObservable()};
  notifyUserAction() {
    this._userActionOccured.next();
  }
  isAuthenticated() {
    const currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) return true;
    return false;
  }

  public getToken(): string {
    const currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.token;
    }
    return null;
  }

  public getCurrentUserId(): string {
    const currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.id;
    }
    return null;
  }

  login(data): Observable<any> {
    return this.http.post(`${this.authUrl}/login`, data).pipe(catchError((error: any) => throwError(error)));
  }

  signup(data): Observable<any> {
    return this.http.post(`${this.authUrl}/signup`, data).pipe(catchError((error: any) => throwError(error)));
  }

  logout() {
    localStorage.removeItem("current_user");
    this.router.navigate(["/login"]);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http
      .post(`${this.authUrl}/forgot-password`, { email })
      .pipe(catchError((error: any) => throwError(error)));
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http
      .put(`${this.authUrl}/forgot-password`, { reset_password_token: token, password })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.assetUrl}/auth/user`).pipe(catchError((error: any) => throwError(error)));
  }

  isAdmin(user: UserModel) {
    return user?.lissa_roles?.includes('order-admin') || user?.user_roles?.includes('order-admin');
  }
}
