<div class="center upload-modal-dialog" mat-dialog-content app-theme>
    <div class="mb-2">
        <h3 class="upload-headline-text p-0">Bulk upload products</h3>
    </div>
    <div class="input-field-layout p-0">
        <form>
          <div class="product_form">
            <div>
              <mat-label>If you want to upload for specific branch?</mat-label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>branch name</mat-label>
                <input type="text" matInput [formControl]="branchId" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="branchAutocompleteView">
                  <mat-option *ngFor="let branch of branchesList" [value]="branch">
                    {{branch?.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="all-drop di1">
          <ngx-file-drop 
          [style]="{height: '200px', textAlign: 'center'}"
          dropZoneLabel="Drop files here" 
          (onFileDrop)="droppedDeviceFile($event)"  
          class="step-inner-box file-dropzone" >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="row upload-box-content">
                    <div class="col-12">
                        <div class="upload-border">
                          <img src="../../../../assets/icons/drop-img-icon.svg" alt="">
                          <p> Drag & drop  or  <span (click)="openFileSelector()"> <a class="browse-link"> browse Files</a> </span> </p>
                        </div>
                    </div>
                </div>  
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
</div>
<div mat-dialog-actions class="mt-3 mb-1 p-3">
    <button mat-button mat-dialog-close class="ml-auto">Cancel</button>
    <!-- <button mat-button class="btn btn-primary" (click)="upload()">Upload</button> -->
  </div>

