import { Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './shared';

export const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'category',
        loadChildren: () => import('./admin/category/category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'clients',
        loadChildren: () => import('./admin/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'products',
        loadChildren: () => import('./admin/product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'services',
        loadChildren: () => import('./admin/service/service.module').then(m => m.ServiceComponentModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: "insurance",
      //   loadChildren: () => import('./admin/product/product.module').then(m => m.ProductModule)
      // },
      {
        path: 'orders',
        loadChildren: () => import('./admin/order/order.module').then(m => m.OrderModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./admin/user/user.module').then(m => m.UserModel),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./admin/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
]
