<div class="center" mat-dialog-content app-theme>
    <div class="mb-2">
        <h3 class="upload-headline-text p-0">Are you sure?</h3>
    </div>
    <div class="input-field-layout p-0">
        <p>Product will be deleted permanently!</p>
    </div> 
</div>
<div mat-dialog-actions class="mt-3 mb-1 p-3 justify-content-center">
    <button mat-button mat-dialog-close class="btn btn-danger">No</button>
    <button mat-button class="btn btn-primary" (click)="confirm()">Yes</button>
  </div>

