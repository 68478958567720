import { BranchModel } from "./index";
import { CompanyModel } from './company.model';

export class UserModel {
  id: number;
  assigned_all_branches: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  status: boolean;
  role: string;
  logo: string;
  corporate_id: string;
  company_id: number;
  branch: BranchModel;
  branch_id: number;
  company: CompanyModel;
  language: string;
  user_branches: BranchModel[];
  user_roles: any[];
  lissa_roles: any[];
  lissa_models: any[];
}
