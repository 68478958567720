<div class="brand-logo">
    <a href="">
        <div class="sidebar-brand"></div>
    </a>
</div>
<div class="nav-links">
    <div class="nav-group">
        <ul>
            <li>
                <a [routerLink]="['/dashboard']"  routerLinkActive="active" #rla="routerLinkActive" class="nav-items">
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" class="mr-2" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0H1.5V2H17.5V0ZM18.5 10V8L17.5 3H1.5L0.5 8V10H1.5V16H11.5V10H15.5V16H17.5V10H18.5ZM9.5 14H3.5V10H9.5V14Z" fill="black" class="sidebar-icon"/></svg>
                    Dashboard</a>
            </li>
        </ul>
    </div>
    <div class="nav-group nav-group-products">
        <h2 class="nav-group-title">Products</h2>
        <div class="divider"></div>
        <ul>
            <li>
                <a [routerLink]="['/products']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}" class="nav-items">
                    <svg width="17" height="20" viewBox="0 0 17 20" class="mr-2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4 4H12.4C12.4 1.79 10.61 0 8.40002 0C6.19002 0 4.40002 1.79 4.40002 4H2.40002C1.30002 4 0.400024 4.9 0.400024 6V18C0.400024 19.1 1.30002 20 2.40002 20H14.4C15.5 20 16.4 19.1 16.4 18V6C16.4 4.9 15.5 4 14.4 4ZM6.40002 8C6.40002 8.55 5.95002 9 5.40002 9C4.85002 9 4.40002 8.55 4.40002 8V6H6.40002V8ZM8.40002 2C9.50002 2 10.4 2.9 10.4 4H6.40002C6.40002 2.9 7.30002 2 8.40002 2ZM12.4 8C12.4 8.55 11.95 9 11.4 9C10.85 9 10.4 8.55 10.4 8V6H12.4V8Z" fill="black" class="sidebar-icon"/></svg>
                        
                    All Products</a>
            </li>
            <li>
                <a  (click)="uploadBulk()" routerLinkActive="active" class="nav-items">
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" class="mr-2" xmlns="http://www.w3.org/2000/svg"><path d="M16.4 0H2.39999C1.28999 0 0.399994 0.9 0.399994 2V16C0.399994 17.1 1.28999 18 2.39999 18H16.4C17.5 18 18.4 17.1 18.4 16V2C18.4 0.9 17.5 0 16.4 0ZM14.4 10H10.4V14H8.39999V10H4.39999V8H8.39999V4H10.4V8H14.4V10Z" fill="black" class="sidebar-icon"/></svg>
                        
                    Add New</a>
            </li>
            <li>
                <a [routerLink]="['/category']" routerLinkActive="active" class="nav-items">
                    <svg width="21" height="20" class="mr-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.81 9.58L10.81 0.58C10.45 0.22 9.94999 0 9.39999 0H2.39999C1.29999 0 0.399994 0.9 0.399994 2V9C0.399994 9.55 0.619994 10.05 0.989994 10.42L9.98999 19.42C10.35 19.78 10.85 20 11.4 20C11.95 20 12.45 19.78 12.81 19.41L19.81 12.41C20.18 12.05 20.4 11.55 20.4 11C20.4 10.45 20.17 9.94 19.81 9.58ZM3.89999 5C3.06999 5 2.39999 4.33 2.39999 3.5C2.39999 2.67 3.06999 2 3.89999 2C4.72999 2 5.39999 2.67 5.39999 3.5C5.39999 4.33 4.72999 5 3.89999 5Z" fill="black" class="sidebar-icon"/></svg>
                        
                    Categories</a>
            </li>
            <li>
                <a [routerLink]="['/services']" class="nav-items">
                    <svg width="23" height="22" class="mr-2" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1 17.9998L13 8.89977C13.9 6.59977 13.4 3.89977 11.5 1.99977C9.49999 -0.0002321 6.49999 -0.400232 4.09999 0.699768L8.39999 4.99977L5.39999 7.99977L0.999994 3.69977C-0.200006 6.09977 0.299994 9.09977 2.29999 11.0998C4.19999 12.9998 6.89999 13.4998 9.19999 12.5998L18.3 21.6998C18.7 22.0998 19.3 22.0998 19.7 21.6998L22 19.3998C22.5 18.9998 22.5 18.2998 22.1 17.9998Z" fill="black" class="sidebar-icon"/></svg>
                        
                    Services</a>
            </li>
            <!-- <li>
                <a [routerLink]="['/insurance']" class="nav-items">Insurance</a>
            </li> -->
        </ul>
        <div class="divider"></div>
    </div>
    <div class="nav-group nav-group-products">
        <ul>
            <li>
                <a [routerLink]="['/orders']" routerLinkActive="active" class="nav-items">
                    <svg width="18" height="20" viewBox="0 0 18 20" class="mr-2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0V14H0V17C0 18.66 1.34 20 3 20H15C16.66 20 18 18.66 18 17V0L16.5 1.5ZM16 17C16 17.55 15.55 18 15 18C14.45 18 14 17.55 14 17V14H5V3H16V17Z" fill="black" class="sidebar-icon"/><path d="M12 5H6V7H12V5Z" fill="black" class="sidebar-icon"/><path d="M15 5H13V7H15V5Z" fill="black" class="sidebar-icon"/><path d="M12 8H6V10H12V8Z" fill="black" class="sidebar-icon"/><path d="M15 8H13V10H15V8Z" fill="black" class="sidebar-icon"/></svg>
                        
                    Orders</a>
            </li>
            <li>
                <a [routerLink]="['/clients']"  routerLinkActive="active" class="nav-items">
                    <svg width="22" class="mr-2" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.67 9.12988C17.04 10.0599 18 11.3199 18 12.9999V15.9999H22V12.9999C22 10.8199 18.43 9.52988 15.67 9.12988Z" fill="black" class="sidebar-icon"/><path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" fill="black" class="sidebar-icon"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C13.53 0 13.09 0.0999998 12.67 0.24C13.5 1.27 14 2.58 14 4C14 5.42 13.5 6.73 12.67 7.76C13.09 7.9 13.53 8 14 8Z" fill="black" class="sidebar-icon"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z" fill="black" class="sidebar-icon"/></svg>

                    Clients</a>
            </li>
            <li>
                <a [routerLink]="['/users']" class="nav-items" routerLinkActive="active">
                    <svg width="20" height="20" class="mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 5H13V2C13 0.9 12.1 0 11 0H9C7.9 0 7 0.9 7 2V5H2C0.9 5 0 5.9 0 7V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V7C20 5.9 19.1 5 18 5ZM7 10C7.83 10 8.5 10.67 8.5 11.5C8.5 12.33 7.83 13 7 13C6.17 13 5.5 12.33 5.5 11.5C5.5 10.67 6.17 10 7 10ZM10 16H4V15.25C4 14.25 6 13.75 7 13.75C8 13.75 10 14.25 10 15.25V16ZM11 7H9V2H11V7ZM16 14.5H12V13H16V14.5ZM16 11.5H12V10H16V11.5Z" fill="black" class="sidebar-icon"/></svg>
                    Users</a>
            </li>
        </ul>
    </div>
</div>

<label class="switch d-flex flex-column align-items-center">
    <!-- <div class="nav-group nav-group-products">
        <a [routerLink]="['/settings']" class="nav-items" routerLinkActive="active">
            <svg width="20" class="mr-2" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.14 10.9399C17.18 10.6399 17.2 10.3299 17.2 9.9999C17.2 9.6799 17.18 9.3599 17.13 9.0599L19.16 7.4799C19.34 7.3399 19.39 7.0699 19.28 6.8699L17.36 3.5499C17.24 3.3299 16.99 3.2599 16.77 3.3299L14.38 4.2899C13.88 3.9099 13.35 3.5899 12.76 3.3499L12.4 0.809902C12.36 0.569902 12.16 0.399902 11.92 0.399902H8.07999C7.83999 0.399902 7.64999 0.569902 7.60999 0.809902L7.24999 3.3499C6.65999 3.5899 6.11999 3.9199 5.62999 4.2899L3.23999 3.3299C3.01999 3.2499 2.76999 3.3299 2.64999 3.5499L0.739988 6.8699C0.619988 7.0799 0.659988 7.3399 0.859988 7.4799L2.88999 9.0599C2.83999 9.3599 2.79999 9.6899 2.79999 9.9999C2.79999 10.3099 2.81999 10.6399 2.86999 10.9399L0.839988 12.5199C0.659988 12.6599 0.609988 12.9299 0.719988 13.1299L2.63999 16.4499C2.75999 16.6699 3.00999 16.7399 3.22999 16.6699L5.61999 15.7099C6.11999 16.0899 6.64999 16.4099 7.23999 16.6499L7.59999 19.1899C7.64999 19.4299 7.83999 19.5999 8.07999 19.5999H11.92C12.16 19.5999 12.36 19.4299 12.39 19.1899L12.75 16.6499C13.34 16.4099 13.88 16.0899 14.37 15.7099L16.76 16.6699C16.98 16.7499 17.23 16.6699 17.35 16.4499L19.27 13.1299C19.39 12.9099 19.34 12.6599 19.15 12.5199L17.14 10.9399ZM9.99999 13.5999C8.01999 13.5999 6.39999 11.9799 6.39999 9.9999C6.39999 8.0199 8.01999 6.3999 9.99999 6.3999C11.98 6.3999 13.6 8.0199 13.6 9.9999C13.6 11.9799 11.98 13.5999 9.99999 13.5999Z" fill="black" class="sidebar-icon"/></svg>
            Settings</a>
    </div> -->
    <mat-slide-toggle (click)="toggle()">Mode</mat-slide-toggle>
</label>
