import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class OrderService {
    private apiUrl = environment.serverUrl;

    constructor(private http: HttpClient) {}

    getOrders(page?: number, limit?: number, filtersObject?: any): Observable<any> {
        let query = `?page=${page}&limit=${limit}`;
        if (filtersObject && filtersObject.haai_order_id) {
            query += `&haai_order_id=${filtersObject.haai_order_id}`;
        }
        return this.http.get(`${this.apiUrl}/order${query}`)
            .pipe(catchError((error: any) => throwError(error)));
   }
}
