export class BranchModel {
    _id: number;
    name: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    zip: string;
    branch_id: number;
    devices: number;
    employees: number;
    company_id: number;
    branch: BranchModel;
    createdAt: string;
    updatedAt: string;
    orders: number;
    users: number;
  }