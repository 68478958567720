import { Theme } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--background': '#121212',
    '--main-background': '#292929',
    '--on-background': '#fff',
    '--on-primary': '#fff',
    '--text-color': '#1F2125',
    '--brand-logo': '../../../../assets/images/ordering-gray.png',
    '--forms': '#fff',
    '--divider-gray': '#fff'
  }
}