import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BranchModel } from '..';

@Injectable()
export class ClientService {
  //private apiUrl = environment.apiUrl;
  private orderUrl = environment.serverUrl;
  
  constructor(private http: HttpClient) {}

  searchBranchesByName(name: string, page?: number, limit?: number): Observable<any> {
    let query = `?page=${page}&limit=${limit}`;
    if (name) {
      query += `&name=${name}`;
    }
    return this.http.get(`${this.orderUrl}/branch${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  searchBranchesById(branchId: number): Observable<any> {
    return this.http.get(`${this.orderUrl}/branch/${branchId}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  assignProductsToClient(products, branchId: number, products_to_unassign?: any): Observable<any> {
    return this.http.post(`${this.orderUrl}/branch/${branchId}/product/assign`, {products, products_to_unassign}, {responseType: 'text'})
    .pipe(catchError((error: any) => throwError(error)));
  }

  getAllBranches(page: number, limit: number): Observable<any> {
    const query = `?page=${page}&limit=${limit}`;
    return this.http.get<BranchModel>(`${this.orderUrl}/branch${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  } 
}
