import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
// import { TranslateService } from '@ngx-translate/core';
@Pipe({name: 'dateFormat'})
export class DateFormat implements PipeTransform {
        constructor() {}
        transform(date: Date | string, format: string): string {
            return moment(date).format(format);
        }
}
