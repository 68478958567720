import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
@NgModule({
  declarations: [NavbarComponent, SidebarComponent],
  imports: [CommonModule, RouterModule, MatButtonToggleModule, MatSlideToggleModule],
  exports: [NavbarComponent, SidebarComponent]
})
export class ComponentsModule {}
