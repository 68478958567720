import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { 
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BranchModel, ClientService, ProductService } from '../..';
import { debounceTime, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-upload-modal',
  templateUrl: './bulk-upload-modal.component.html',
  styleUrls: ['./bulk-upload-modal.component.css']
})
export class BulkUploadModalComponent implements OnInit {
  public deviceFiles: NgxFileDropEntry[] = [];
  public branchId = new FormControl();
  branchesList: BranchModel[] = [];
  filteredBranches: Subscription;
  constructor(
    private productService: ProductService,
    private toastr: ToastrService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.initFilters();
  }


  public droppedDeviceFile(files: NgxFileDropEntry[]) {
    this.deviceFiles = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.handleUpload(file, 'bulk');
          // Here you can access the real file
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;      }
    }
  }

  handleUpload(file, type?) {
    const reader = new FileReader();
    if (type === 'bulk') {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.productService.uploadBulkProducts(reader.result, this.branchId?.value?._id || null)
          .subscribe(res => {
            if (res) {
              this.toastr.success('', 'Bulk file uploaded successfully');
              this.productService.productsUploaded.emit(1);
            }
          });
      };
    }
  }

  initFilters() {
    this.filteredBranches = this.branchId.valueChanges
      .pipe(startWith(null))
      .pipe(debounceTime(500))
      .pipe(
        tap(val => {
          if (val && typeof val !== 'object') {
            this.clientService
              .searchBranchesByName(val, 1, 10000)
              .subscribe((res) => {
                if (res.length !== 0) {
                  this.branchesList = res.docs;
                } else {
                  this.branchesList = [];
                }
              });
          } else if (val === '') {
          }
        })
      )
      .subscribe();
  }
  branchAutocompleteView(branch): string | undefined {
    if (branch) {
      return branch.name;
    } else {
      return undefined;
    }
  }
}
