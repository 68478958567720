import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
    
@Pipe({name: 'safehtml'})
    
export class SafeHtmlPipe implements PipeTransform {
      constructor(private sanitized: DomSanitizer) {
      }
      transform(value): SafeHtml {
        return this.sanitized.bypassSecurityTrustUrl(value);
      }
    }