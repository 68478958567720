<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-minimize">
          <!-- <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
            <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
            <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
          </button> -->
        </div>
      </div>
      <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span> <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span> <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navigation">
        <ul class="navbar-nav">
          <!-- <li class="nav-item dropdown">
            <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">notifications</i> <span class="notification">22</span>
              <p><span class="d-lg-none d-md-block">Some Actions</span></p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item"></a>
            </div>
          </li> -->
          <li class="nav-item d-sm">
            <a class="nav-link" (click)="logout()">
              <i class="material-icons">exit_to_app</i>
              <p><span class="d-lg-none d-md-block">Account</span></p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  