import { Component, ElementRef, OnInit } from '@angular/core';
import { Theme } from 'src/assets/theme/symbols';
import { ThemeService } from 'src/assets/theme/theme.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BulkUploadModalComponent } from '../../modal-dialogs/bulk-upload-modal/bulk-upload-modal.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public activeTheme: Theme;
  public dialogConfig = new MatDialogConfig();
  constructor(
    private themeService: ThemeService,
    public dialog: MatDialog,
    ) { }
  ngOnInit(): void {}

  toggle() {
    const active = this.themeService.getActiveTheme();
    if (active.name === 'light') {
      this.themeService.setTheme('dark');
    } else {
      this.themeService.setTheme('light');
    }
  }

  uploadBulk() {
    this.dialogConfig.width = '550px';
    const uploadDialog = this.dialog.open(BulkUploadModalComponent, this.dialogConfig);
    uploadDialog.afterClosed().subscribe(res => console.log(res));
  }
}
