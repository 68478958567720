import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ProductModel } from "../models";

@Injectable()
export class ProductService {
    private apiUrl = environment.serverUrl;
    public productsUploaded: EventEmitter<any>;

    constructor(private router: Router, private http: HttpClient) {
        this.productsUploaded = new EventEmitter();
    }

    createProduct(product): Observable<any> {
        return this.http.post(`${this.apiUrl}/product`, product)
        .pipe(catchError((error: any) => throwError(error)));
    }

    getProducts(page?: number, limit?: number, filtersObject?: any): Observable<any>{
        const query = `?page=${page}&limit=${limit}`;
        let params = new HttpParams();
        Object.keys(filtersObject).forEach(k => {
            if (filtersObject[k] && typeof filtersObject[k] != 'object') {
                params = params.set(k, filtersObject[k]);
            }
        });
        return this.http.get<ProductModel[]>(`${this.apiUrl}/product${query}`, {params})
        .pipe(catchError((error: any) => throwError(error)));
    }

    getServices(page?: number, limit?: number, type?: string): Observable<any> {
        const query = `?page=${page}&limit=${limit}&type=${type}`;
        return this.http.get<ProductModel[]>(`${this.apiUrl}/product${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getProductById(productId): Observable<any> {
        return this.http.get<ProductModel>(`${this.apiUrl}/product/${productId}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateProduct(productId, newProduct): Observable<any> {
        return this.http.patch<ProductModel>(`${this.apiUrl}/product/${productId}`, newProduct)
        .pipe(catchError((error: any) => throwError(error)));
    }

    deleteProduct(productId: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}/product/${productId}`, {responseType: 'text'})
        .pipe(catchError((error: any) => throwError(error)));
    }
    deleteProductImage(prodId: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}/product/${prodId}/delete-image`, {responseType: 'text'})
        .pipe(catchError((error: any) => throwError(error)));
    }
    uploadBulkProducts(file: any, branchId?: number): Observable<any> {
        let query = ''; 
        if(branchId) {
            query = `?branch_id=${branchId}`;
        }
        return this.http.post(`${this.apiUrl}/product/bulk${query}`, {file}, {responseType: 'text'})
        .pipe(catchError((error: any) => throwError(error)));
    }
}
