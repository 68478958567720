import { NgModule } from "@angular/core";
import { AvatarInitialsPipe, DateFormat } from ".";



@NgModule({
  declarations: [AvatarInitialsPipe, DateFormat],
  imports: [],
  exports: [AvatarInitialsPipe, DateFormat]
})
export class MainPipe {}
