import { Theme } from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    '--background': '#fff',
    '--main-background': '#F5F5F5',
    '--on-background': '#000',
    '--on-primary': '#000',
    '--text-color': '#fff',
    '--brand-logo': '../../../../assets/images/ordering.png',
    '--forms': '#292929',
    '--divider-gray': '#DADDEC'
  }
};
